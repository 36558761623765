<template>
  <div class="skeleton--container">
    <div class="left--skeleton">
      <page-select></page-select>
    </div>
    <div class="right--skeleton" style="position: relative">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import PageSelect from '@/components/utils/page-select';

export default {
  name: 'view-skeleton',
  components: { PageSelect },
};
</script>

<style scoped></style>
