<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <change-password />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import ChangePassword from '@/components/change-password/change-password';

import DefaultHeadMixin from '@/mixins/defaultHead';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    ChangePassword,
    ViewSkeleton,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.changePass'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
};
</script>
