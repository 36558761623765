<template>
  <section class="page--selection">
    <div v-if="pageSelectType === 'inbox-notif'">
      <router-link
        to="/inbox"
        class="select--item"
        :class="{ selected: $route.name === 'inbox' || $route.name === 'inbox-detail' }"
      >
        {{ $t('home.sidebarMenu.inbox') }}
      </router-link>
      <router-link
        to="/notifications"
        class="select--item"
        :class="{ selected: $route.name === 'notifications' }"
      >
        {{ $t('home.sidebarMenu.notification') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'profile-pass'">
      <router-link
        to="/profile"
        class="select--item"
        :class="{ selected: $route.name === 'profile' }"
      >
        {{ $t('home.sidebarMenu.changeProfile') }}
      </router-link>
      <router-link
        to="/change-password"
        class="select--item"
        :class="{ selected: $route.name === 'change-password' }"
      >
        {{ $t('loginRegister.changePass') }}
      </router-link>
      <router-link
        to="/referral-code"
        class="select--item"
        :class="{ selected: $route.name === 'referral-code' }"
      >
        {{ $t('referralCode.title') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'help-desk'">
      <router-link
        to="/mitra-agen"
        class="select--item"
        :class="{ selected: $route.name === 'agent-partner' }"
      >
        {{ $t('general.agentPartner') }}
      </router-link>
      <router-link
        to="/mitra-proyek"
        class="select--item"
        :class="{ selected: $route.name === 'developer-partner' }"
      >
        {{ $t('general.developerPartner') }}
      </router-link>
      <router-link
        to="/bantu-cari-hunian"
        class="select--item"
        :class="{ selected: $route.name === 'help-find-residence' }"
      >
        {{ $t('general.helpFindResidence') }}
      </router-link>
    </div>
    <div v-else-if="pageSelectType === 'help'">
      <div
        class="select--item"
        :class="{ selected: selectedHelp && selectedHelp.url === help.url }"
        @click="selectHelp(help)"
        v-for="(help, index) in helpList"
        :key="`help-${index}`"
      >
        {{ $t(help.title) }}
      </div>
    </div>
    <div v-else-if="pageSelectType === 'coin'">
      <router-link
        to="/coin-usage"
        class="select--item"
        :class="{ selected: $route.name === 'coin-usage' }"
      >
        <v-icon>mdi-database-search</v-icon>
        <div>{{ $t('home.sidebarMenu.coinUsage') }}</div>
      </router-link>
      <router-link
        to="/coin-purchase"
        class="select--item"
        :class="{ selected: $route.name === 'coin-purchase' }"
      >
        <v-icon>mdi-cart</v-icon>
        <div>{{ $t('home.sidebarMenu.coinPurchase') }}</div>
      </router-link>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      selectedHelp: (state) => state.help.selectedHelp,
      helpList: (state) => state.help.helpList,
    }),
    pageSelectType() {
      if (
        this.$route.name === 'inbox' ||
        this.$route.name === 'inbox-detail' ||
        this.$route.name === 'notifications'
      ) {
        return 'inbox-notif';
      } else if (
        this.$route.name === 'profile' ||
        this.$route.name === 'change-password' ||
        this.$route.name === 'bank-account' ||
        this.$route.name === 'referral-code'
      ) {
        return 'profile-pass';
      } else if (
        this.$route.name === 'agent-partner' ||
        this.$route.name === 'developer-partner' ||
        this.$route.name === 'help-find-residence'
      ) {
        return 'help-desk';
      } else if (this.$route.name === 'help-index' || this.$route.name === 'help') {
        return 'help';
      } else if (this.$route.name === 'coin-purchase' || this.$route.name === 'coin-usage') {
        return 'coin';
      } else {
        return null;
      }
    },
  },
  methods: {
    selectHelp(help) {
      this.$store.commit('help/SET_SELECTED_HELP', help);
      this.$router.push({
        path: '/help/' + help.url,
        query: { ...this.$route.query },
      });
    },
  },
};
</script>
